<!--
 * @Descripttion: 
 * @Author: 张明尧
 * @Date: 2021-02-04 11:26:21
 * @LastEditTime: 2021-02-04 13:45:36
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>