/*
 * @Descripttion: main.js
 * @Author: 张明尧
 * @Date: 2021-02-01 13:55:54
 * @LastEditTime: 2021-03-19 10:44:20
 */
import Vue from 'vue'
import Main from '@/pages/main'
import ajax from '@/util/js/ajax';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router/router'
import { getWebsiteVisitLog } from './util/actions/api'
import './util/js/rem'
import './assets/iconfont/iconfont.css'
import './util/style/common/animation.css';
// 视频播放插件
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
// 地图
// import BaiduMap from 'vue-baidu-map';

// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'RkonaInfprG0ks0K7mq4OsBezsjgWbuR',
// });

Vue.use(VideoPlayer)
Vue.use(ElementUI);
Vue.use(ajax);
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  if(Object.keys(to.meta).length > 0) {
    getWebsiteVisitLog({...to.meta});
  }
  next()
})

new Vue({
  router,
  render: h => h(Main),
}).$mount('#app')
