/*
 * @Descripttion: 全局设置屏幕兼容的rem
 * @Author: 张明尧
 * @Date: 2021-02-22 13:42:19
 * @LastEditTime: 2021-03-04 17:58:49
 */
const baseSize = 90;
function setRem() {
  // 基准大小
  const scale = document.documentElement.clientWidth / 1920;
    // 设置页面根节点字体大小
    // document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2))<=20?20+ 'px':(baseSize * Math.min(scale, 2)) + 'px';
    document.documentElement.style.fontSize = `${baseSize * Math.min(scale, 2)}px`;
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = () => {
  setRem();
};