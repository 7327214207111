/*
 * @Descripttion: 路由总页面
 * @Author: 张明尧
 * @Date: 2021-02-03 15:26:00
 * @LastEditTime: 2021-03-22 19:07:04
 */
import Vue from 'vue';
import Router from 'vue-router';
const indexPage = () => import('@/pages/index/index.vue'); // 主页框架
const homePage = () => import('@/pages/home/index.vue'); // 首页
const productPage = () => import('@/pages/product/index.vue'); // 产品
const philosophyPage = () => import('@/pages/philosophy/index.vue'); // 哲学页面
const experiencePage = () => import('@/pages/experience/index.vue'); // 体验
const schoolPage = () => import('@/pages/school/index.vue'); // 学院
const buyPage = () => import('@/pages/buy/index.vue'); // 购买
const aboutPage = () => import('@/pages/about/index.vue'); // 关于
const downloadAppPage = () => import('@/pages/downloadApp/index.vue'); // 下载APP
Vue.use(Router);
export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'indexPage',
      redirect:'/home',
      component: indexPage,
      children:[
        {
          path: 'home',
          name: 'homePage',
          meta: {
            pageName: '企业大脑-首页', // 企业大脑站点访问记录
            pageCode: 'danao_home',
            url: 'http://www.jedanao.com/home',
          },
          component: homePage,
        },
        {
          path: 'product',
          name: 'productPage',
          meta: {
            pageName: '企业大脑-产品', // 企业大脑站点访问记录
            pageCode: 'danao_product',
            url: 'http://www.jedanao.com/product',
          },
          component: productPage,
        },
        {
          path: 'philosophy',
          name: 'philosophyPage',
          meta: {
            pageName: '企业大脑-哲学', // 企业大脑站点访问记录
            pageCode: 'danao_philosophy',
            url: 'http://www.jedanao.com/philosophy',
          },
          component: philosophyPage,
        },
        {
          path: 'experience',
          name: 'experiencePage',
          meta: {
            pageName: '企业大脑-体验', // 企业大脑站点访问记录
            pageCode: 'danao_experience',
            url: 'http://www.jedanao.com/experience',
          },
          component: experiencePage,
        },
        {
          path: 'school',
          name: 'schoolPage',
          // meta: {
          //   pageName: '企业大脑-学院', // 企业大脑站点访问记录
          //   pageCode: 'danao_school',
          //   url: 'http://www.jedanao.com/school',
          // },
          component: schoolPage,
        },
        {
          path: 'buy',
          name: 'buyPage',
          meta: {
            pageName: '企业大脑-购买', // 企业大脑站点访问记录
            pageCode: 'danao_buy',
            url: 'http://www.jedanao.com/buy',
          },
          component: buyPage,
        },
        {
          path: 'about',
          name: 'aboutPage',
          meta: {
            pageName: '企业大脑-关于', // 企业大脑站点访问记录
            pageCode: 'danao_about',
            url: 'http://www.jedanao.com/about',
          },
          component: aboutPage,
        },
      ]
    },
    {
      path: '/downloadApp',
      name: 'downloadAppPage',
      component: downloadAppPage,
    },
  ]
})