/*
 * @Descripttion: 
 * @Author: 张明尧
 * @Date: 2021-03-01 14:51:07
 * @LastEditTime: 2021-03-19 10:35:11
 */
import fetch from '@/util/js/ajax';

// 获取学院的菜单接口
export function getSchoolMenu(param) {
  return fetch({ url: '/jepaas/college/loadProductInfo', data: param })
    .then(result => result.data)
    .catch();
}

// 获取视频详情
export function getVideoDetails(param) {
  return fetch({ url: '/jepaas/vod/getVideoPlayAuth', data: param })
    .then(result => result.data)
    .catch();
}
// 获取loadTree菜单的tree
export function getloadTree(param) {
  return fetch({ url: '/jepaas/college/loadTree', data: param })
    .then(result => result.data)
    .catch();
}

// 获取对应菜单的数据
export function getloadProductInfo(param) {
  return fetch({ url: '/jepaas/college/loadProductInfo', data: param })
    .then(result => result.data)
    .catch();
}


// 站点访问信息
export function getWebsiteVisitLog(param) {
  return fetch({ url: '/je/product/paas/adminCopyright/websiteVisitLog', data: param })
    .then(result => result.data)
    .catch();
}